<template>
  <b-card class="mb-2 library-card answer-links" align="left">
    <b-row class="library-card-tag-alignment">
      <div class="d-flex align-items-center library-card-tag">
        <span class="padding-passage">PASSAGE {{passage.order }}</span>
      </div>
      <a :id="'passage_'+passage.order"/>
      <a :id="passage.doc_id"/>
      <a v-for="anchor in passage.anchors" :key="anchor" :ref="anchor" :id="anchor"/>
      <b-col offset="8" lg="2" class="pb-0 d-flex align-right mobile-alignment">
        <b-row class="w-100 margin-action-passages">
          <img @click="copyText(passage.text)" class="cursor-pointer" :src="'/assets/images/icon_copy.svg'"
               v-b-popover.hover.bottom="messageText" v-if="canCopyLink"/>
          <img @click="copyUrl(documentId, passage.link)" class="cursor-pointer library-document-action-icon"
               :src="'/assets/images/icon_share.svg'" v-if="canCopyLink"
               v-b-popover.hover.bottom="messageLink"/>
        </b-row>
      </b-col>
    </b-row>

    <markdown-to-html :source="passage.text" :docId="passage.doc_id"></markdown-to-html>

    <b-row class="d-flex library-document-bottom-elements">
      <div v-for="(tag, index) in passage.tags" :key="index">
        <p class="pill-tags openSans-400 fz-12" v-if="!passage.anchors.includes(tag)">{{ tag }}</p>
      </div>
      <div v-for="tag in passage.tags" :key="tag">
        <p class="pill-tags openSans-600 fz-12" v-if="passage.anchors.includes(tag)">{{tag}}</p>
      </div>
      <div class="library-document-bottom-file openSans-400 fz-12">{{titleDoc}}
        <img :src="'/assets/images/icon_book.svg'"/>
      </div>
    </b-row>
  </b-card>
</template>
<script>
import tools from "@/modules/common/utils/tools.js"
import {PERM_OVER_CONTENT_PROTECT} from "@/modules/security/services/SecurityService.js"
import MarkdownToHtml from '@/modules/common/components/MarkdownToHtml.vue'

export default {
  name: 'passage-card',
  components: { MarkdownToHtml },
  props: {
    docId: String,
    passage: Object,
    titleDoc: String
  },
  data() {
    return {
      messageText: this.$t('myContent.document.passage.popover.copyText'),
      messageLink: this.$t('knowledge.ask.popover.copyLink'),
      documentId: ''
    }
  },
  methods: {
    copyText(text) {
      tools.copyToClipboard(text)
      this.messageText = this.$t('myContent.document.passage.popover.copied')
    },
    copyUrl(docId, passageNum) {
      tools.copyToClipboard(`${window.location.origin}/${this.$store.getters.getInstance}/document/${ docId }#${ passageNum }`)
      this.messageLink = this.$t('knowledge.ask.popover.copied')
    }
  },
  created() {
    this.documentId = this.docId
  },
  computed: {
    botPermissions: function() {
      return this.$store.getters.getInstancePermissions
    },
    canCopyLink: function() {
      return this.botPermissions.indexOf(PERM_OVER_CONTENT_PROTECT) > -1
    }
  }

}
</script>
<style lang="scss" scoped>

div.popover::v-deep {
  border: 1px solid var(--viridian);

  div.popover-body {
    color: var(--viridian) !important;
    padding: 0.5rem 0.75rem;
    min-height: 37px;
    text-align: center;
  }

}

.margin-action-passages {
  margin-left: 35px;
}

.margin-passages {
  margin: 30px 40px;
}

.padding-passage {
  padding-left: 39px;
}


@media (min-width: 320px) and (max-width: 1024px) {
  div.library-card-tag-alignment {
    width: 100%;
    padding-left: 0rem !important;
  }
  .card-body {
    padding:0px;
  }
  div.d-flex.align-right.col-lg-2 {
    margin-left: auto !important;
  }
  .mobile-alignment {
    width: auto;
  }
  div.library-document-bottom-elements {
    padding-left: 20px;
    padding-right: 20px;

    div.library-document-bottom-file {
      margin-top: 1rem;
      text-align: center;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;

      }
    }
  }
}

</style>