<template>
  <div class="container-base">
    <div class="library-doc-container">
      <div v-if="!titleDoc" class="d-flex full-view-height justify-content-center m-3">
        <preloader :type="'pdf-preview'" />
      </div>
      <div v-if="titleDoc" class="table-list-title openSans-400 fz-22 my-2 text-left">
        <b-row class="file-name-back">
          <back-button class="my-auto pl-15" :title="'to Entries'" />
          <div class="library-document-title openSans-100 fz-28 file-title-trim w-100 pl-15" v-b-tooltip.hover :title="titleDoc">
            {{ titleDoc }}
          </div>
        </b-row>
        <div class="download-div"><img class="resize" @click="downloadDoc" :src="'/assets/images/archivo.svg'" v-if="canDownloadPDF"></div>
        <p class="library-document-update-span openSans-400 fz-13 ml-0" v-if="totalPassages > 0">
          {{ uploaded ? uploaded + ', ' : '' }} {{ $tc('general.list.passages', totalPassages, { count: totalPassages }) }}
        </p>
      </div>

      <div :class="is_mobile ? 'mx-0' : ''" class="row search-bar">
      <div :class="is_mobile ? 'col-12' : 'col-9 my-3'">
        <b-form-input type="search" :placeholder="$t('myApps.qaPairs.filters.search')" v-model="searchText"
                      @keyup.enter="onkeySearch"
                      @search="cleanSearch"/>
      </div>
      <div :class="is_mobile ? 'col-12 mx-auto justify-content-center my-3' : 'col-3 my-auto'" class="d-flex">
        <img  class="step-icon" @click="scrollMeToPrev" :src="'/assets/images/left-arrow.svg'">
        <div class="mx-3"> {{ `${searchId} / ${searchCounter}` }}</div>
        <img class="step-icon rotate-icon"  @click="scrollMeToNext" :src="'/assets/images/left-arrow.svg'">
      </div>
      </div>

      <div v-for="passage in passages" :key="passage.id">
        <div v-if="!passageHasForbiddenTags(passage)">
          <div :ref="'passage_'+passage.order"></div>
          <passage-card :doc-id="docId" :passage="passage" :title-doc="titleDoc"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LibraryService from "@/modules/library/services/LibraryService.js"
import Preloader from "@/modules/common/components/Preloader.vue"
import BackButton from "@/modules/common/components/BackButton.vue"
import download from "downloadjs";
import debounce from 'lodash/debounce'
import PassageCard from "@/modules/library/components/PassageCard";
import {PERM_OVER_CONTENT_PROTECT} from "@/modules/security/services/SecurityService";

const $ = require('jquery')
window.$ = $

export default {
  name: 'Document',
  components: { PassageCard, Preloader, BackButton},
  data () {
    return {
      searchText: '',
      oldSearch: null,
      searchCounter: 0,
      searchId: 0,
      currentSearchId: 'searchId',
      totalPassages: 0,
      titleDoc: '',
      uploaded: '',
      passages: [],
      currentPage: 1,
      docId: '',
      storedName: '',
      textFilter: '',
      copyMessage: this.$t('knowledge.ask.popover.copyLink'),
      copyTextCard: this.$t('myContent.document.passage.popover.copyText'),
      space: /\s|\r|\n/,
      urlPattern: /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/,
      filePattern: /\[file[\w-]*/,
      markdownUrlPattern: /\[.*\]\(.*\)/g
    }
  },
  methods: {
    onkeySearch() {
      this.searchText = this.searchText.trim()
      if (this.searchText == this.oldSearch) {
        return
      }
      if(this.searchText !== '') {
        this.oldSearch = this.searchText
        this.searchCounter = 0
        this.searchId = 0
        let regSearch = new RegExp(this.searchText, "gi");
        this.passages.forEach(passage => {
          passage.text = this.highlightsText(passage.originalText, regSearch)
        })
      } else {
        this.passages.forEach(passage => {
          passage.text = passage.originalText
        })
        this.searchCounter = 0
        this.searchId = 0
        this.oldSearch = null
      }
    },
    highlightsText(text, regSearch) {
      var result;
      var markedText = ''
      let currentIdx = 0
      let regSpace = new RegExp(this.space, "gi");
      // remove anchor and tags lines
      text = this.removeSpecialLines(text)
      // create a list with all the markdown urls found in the passage
      let allMarkdownUrlsMatches = text.matchAll(this.markdownUrlPattern)
      let allMarkdownUrls = []
      for (const match of allMarkdownUrlsMatches) {
        allMarkdownUrls.push(String(match))
      }
      while((result = regSearch.exec(text)) !== null) {
        if (!this.isRegWithLink(text, result['index'], regSpace, allMarkdownUrls)) {
          this.searchCounter++
          markedText += text.substring(currentIdx, result['index']) + `<mark id="searchId${this.searchCounter}" style="background-color: #FFE2B7;">${result[0]}</mark>`
          currentIdx = result['index'] + result[0].length
        }
      }
      markedText += text.substring(currentIdx)

      return markedText
    },
    removeSpecialLines(text) {
      // remove lines with "tags:" or "anchors:" data
      let processedText = ''
      text.split('\n').forEach(line => {
          if (line.indexOf('tag:') != 0 && line.indexOf('anchor:') != 0) {
            processedText += `${line}\n`
          }
      })
      return processedText
    },
    scrollMeToNext() {
      if(this.searchText.trim() === '') { return; }
      this.searchId++
      let prevSearch = this.currentSearchId + (this.searchId -1)
      if (this.searchId > this.searchCounter) {
        this.searchId = 1
        prevSearch = this.currentSearchId + this.searchCounter
      }

      this.setCurrentSearchBackground()
      if(prevSearch !== 'searchId0') {
        document.getElementById(prevSearch).style.background = '#FFE2B7'
      }
    },
    setCurrentSearchBackground() {
      let currentSearch = this.currentSearchId + this.searchId

      let top = document.getElementById(currentSearch).getBoundingClientRect().top + document.documentElement.scrollTop;
      let gapSearchBar = 90
      window.scrollTo({top: top-gapSearchBar, behavior: "smooth"});

      document.getElementById(currentSearch).style.background = '#FDFF47'
    },
    scrollMeToPrev() {
      if(this.searchText.trim() === '') { return; }
      this.searchId--
      let prevSearch = this.currentSearchId + (this.searchId +1)
      if (this.searchId <= 0) {
        this.searchId = this.searchCounter
        prevSearch = this.currentSearchId + 1
      }

      this.setCurrentSearchBackground()
      if((prevSearch !== 'searchId0') && (prevSearch !== (this.currentSearchId + (this.searchCounter+1)))) {
        document.getElementById(prevSearch).style.background = '#FFE2B7'
      }
    },
    isRegWithLink(passageText, index, regSpace, allMarkdownUrls) {
      let result;
      let spaceMatch;
      // look for the last space closest to the `index`
      // let spaceMatches = passageText.match(regSpace)
      while(((result = regSpace.exec(passageText)) !== null) && result['index'] < index) {
        spaceMatch = result
      }

      // spaceMatch: if it is undefined, the previous `result` is configured.
      spaceMatch = spaceMatch || result
      let nextSpace = regSpace.exec(passageText)
      if (nextSpace !== null) {
        index = nextSpace['index']
      }

      let fromBeginning = passageText.substring(spaceMatch['index'], index)
      for (const markdownUrl of allMarkdownUrls) {
        if (markdownUrl.trim().toLowerCase().indexOf(fromBeginning.trim().toLowerCase()) > -1) {
          return true
        }
      }

      return this.urlPattern.test(fromBeginning) || this.filePattern.test(fromBeginning)
    },
    cleanSearch() {
      if(!this.searchText) {
        this.searchText = ''
        this.oldSearch = null
        this.searchCounter = 0
        this.searchId = 0
        this.passages.forEach(passage => {
          passage.text = passage.originalText
        })
      }
    },
    passageHasForbiddenTags(passage) {
      if (passage.tags.length == 0 || this.$store.getters.getInstanceForbiddenTags == 0) {
        return false
      }
      let intersecting = passage.tags.filter(x => this.$store.getters.getInstanceForbiddenTags.includes(x))
      return intersecting.length > 0
    },
    searchDocument(quantity) {
      LibraryService.getDocument(this.$router.currentRoute.params.bot,
                                 this.$route.params.id, quantity).then(response => {
        this.updatePassages(response, quantity)
      }, error => {
        console.log(error)
        this.$router.push(`/${this.$store.getters.getInstance}/library`)
      })
    },
    searchDocumentByTags(quantity) {
      console.log('searchDocumentByTags: ' + this.$route.params.tags)
      LibraryService.getDocumentByTags(this.$router.currentRoute.params.bot,
                                 this.$route.params.tags, quantity).then(response => {
        this.updatePassages(response, quantity)
      }, error => {
        console.log(error)
        this.$router.push(`/${this.$store.getters.getInstance}/library`)
      })
    },
    updatePassages(response, quantity) {
        // we  update the passages with the response. We do it if the list is empty, to avoid a race condition
        // between the partial and total requests
        if (this.passages.length === 0 && quantity === undefined) {
          this.totalPassages = response.data.passages_count
          this.titleDoc = response.data.title
          // We duplicate the passages text to highlight them correctly
          response.data.passages.forEach(passage => {
            passage.originalText = passage.text
          })
          this.passages = response.data.passages
          this.uploaded = response.data.uploaded
          this.docId = response.data.doc_id.replace('/', '-')
          this.storedName = response.data.stored_name
          this.$nextTick(() => {
            // we need a litle more time to be sure all the passage cards
            // have been rendered
            setTimeout(() => {this.updateLocalLinks()}, 500);
          })
          this.scrollToAnchor()
        }
    },
    imageLoaded() {
      this.debouncedScrollToAnchor()
    },
    localLink(e) {
      // avoid reload the page with local links
      e.preventDefault()
      // scroll to element
      let hash = e.target.hash
      hash = hash.substr(1)  // remove the #
      this.scrollToElement(document.getElementById(hash))
    },
    scrollToElement(element) {
      // set the scroll just at the bottom of the search bar
      let scrollBarHeight = document.getElementsByClassName('search-bar')[0].getBoundingClientRect().height;
      let elementTop = element.getBoundingClientRect().y
      window.scrollTo(0, elementTop + window.scrollY - scrollBarHeight)
    },
    updateLocalLinks() {
      // prevent links pointing to other parts of this document
      // to reload the page
      let location = String(window.location)
      const links = document.getElementsByTagName("a");
      for (let index = 0; index < links.length; index++) {
        let link = links[index]
        if (String(link).indexOf(location) === 0 && link.hash != '') {
          if(link.onclick == undefined) {
            link.onclick = this.localLink
          }
        }
      }
    },
    scrollToAnchor() {
      if (this.searchText != '') {
        // do not interfere with the search
        return
      }
      let hash = location.hash
      if (hash !== '') {
        if (hash.indexOf('?') > 0) {
          // remove the query params
          hash = hash.substr(0, hash.indexOf('?'))
        }
        hash = hash.substr(1)
        this.$nextTick(() => {
            var imgs = document.getElementsByTagName("img");
            for (var i = 0; i < imgs.length; i++) {
                if (imgs[i].onload == undefined) {
                  imgs[i].onload = this.imageLoaded
                }
            }
            var element = document.getElementById(hash);
            this.scrollToElement(element)
        })
      }
    },
    debouncedScrollToAnchor: debounce(function () {
      this.scrollToAnchor(); }, 500),
    downloadDoc() {
      document.body.style.cursor = 'wait'
      LibraryService.downloadDocument(
          this.storedName).then((response) => {
            const content = response.headers['content-type'];
            const fileName = this.storedName.substr(
              this.storedName.lastIndexOf(this.docId) + this.docId.length + 1);
            document.body.style.cursor = 'default'
            download(response.data, fileName, content)
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },
  created() {
    if(this.$route.name == 'Document') {
      this.searchDocument()
    }
    if(this.$route.name == 'DocumentByTags') {
      this.searchDocumentByTags()
    }
    this.currentPage = this.$route.query.page
    if (this.$route.query.text_filter != undefined && this.$route.query.text_filter != 'undefined') {
      this.textFilter = this.$route.query.text_filter
    }
  },
  computed: {
    is_mobile() {
        const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
        return isMobile.matches ? true : false
    },
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    },
    canDownloadPDF() {
      return this.botPermissions.indexOf(PERM_OVER_CONTENT_PROTECT) > -1
    }
  },
  updated() {
    this.debouncedScrollToAnchor()
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>

.step-icon {
  width: 20px;
  cursor: pointer;
}

.rotate-icon {
  transform: rotate(180deg)
}
.search-bar {
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #f8f8f8;
}

.padding-row {
  padding-left: -15px;
}

.ml-15 {
  margin-left: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.resize {
  width: 25px;
  margin-bottom: 10px;
  cursor: pointer;
}


@media (min-width: 320px) and (max-width: 1024px) {
  .library-doc-container {
    padding: 20px;
  }
  .table-list-title {
    margin-top: 20px !important;
  }
  div.library-document-title {
    width: 100%;
    text-overflow: unset !important;
    overflow: unset !important;
    white-space: unset !important;
  }
  .download-div {
    text-align: right;
    padding-right: 20px;
  }
  .library-document-update-span {
    padding-left: 15px;
  }
}

</style>